/* eslint camelcase: off */
// import Vue from 'vue';
import dayjs from 'dayjs';

/**
 * Common utility functions that are, or will be, used in different Vue components.
 * @todo Consider breaking these out into different files, e.g. WeatherUtils.js, DateUtils.js,
 * etc. if this collection gets too large.
 */

/**
 * @todo Consider moving to /util/Constants.js if/when we have more of these!
 */
export const dataNotAvailable = 'N/A';
export const defaultColor = 'var(--text-darkest)';

export const powderLevel = {
  canada: 15,
  imperial: 6,
  metric: 15,
  uk: 6,
  us: 6,
};

export const precipTypes = {
  none: 0,
  snow: 1,
  mix: 2,
  rain: 3,
};

/**
 * Available status values for a resort report
 * @see https://feeds.snocountry.net/#Levels_Fields
 * @see https://github.com/cloudninewx/OpenMountain-API/blob/develop/models/ResortReport.js#L123
 */
export const resortReportStatus = {
  unknown: -1,
  notAvailable: 0,
  open: 1,
  temporarilyClosed: 2,
  closed: 3,
  summerOperations: 4,
};

export const riskColors = {
  high: '#dc3545',
  medium: '#ffc107',
  low: '#28a745',
};

export function addEmptyDays(forecastDaily, numDays = 3) {
  const startDate = forecastDaily[forecastDaily.length - 1].display_at;
  const startDayJs = dayjs.utc(startDate);

  for (let i = 0; i < numDays; i += 1) {
    const nextDayJs = startDayJs.add(i + 1, 'day');

    forecastDaily.push({
      display_at: `${nextDayJs.format('YYYY-MM-DDThh:mm:ss')}Z`,
      display_at_local_label: nextDayJs.format('ddd D'),
    });
  }
}

export function addQueryParamsToPath(path, query = {}) {
  if (Object.keys(query).length === 0) {
    return path;
  }

  const [originalPath, queryString = ''] = String(path).split('?');
  const searchParams = new URLSearchParams(queryString);
  Object.entries(query).forEach(([key, value]) => searchParams.set(key, value));

  return `${originalPath}?${searchParams.toString()}`;
}

export function addIsCustomToPath(path, location) {
  if (location?.is_custom) {
    return addQueryParamsToPath(path, { is_custom: true });
  }

  return path;
}

export function addInAppViewToPath(path, inAppView) {
  if (inAppView) {
    return addQueryParamsToPath(path, { in_app_view: true });
  }

  return path;
}

/**
 * @see https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript#answer-33034768
 */
export function areArraysDifferent(array1 = [], array2 = []) {
  return array1
    .filter((el) => !array2.includes(el))
    .concat(array2.filter((el) => !array1.includes(el)))
    .length > 0;
}

/**
 * Convert inches to cms
 * @todo Use conversion methods from node-forecasts-lib!
 * @see https://github.com/cloudninewx/node-forecasts-lib
 */
export function convertInToCm(value) {
  if (value === 0) {
    return 0;
  }

  return Math.round(value * 2.54);
}

/**
 * @see https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro
 */
export function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes.
  return div.firstChild;
}

export function getElevationUnits(units, useSymbol = false) {
  if (units === 'imperial') {
    return useSymbol ? '\'' : 'ft';
  }

  return 'm';
}

export function getLocationPath(location, skiAreaLocationTypes) {
  const { shortname, slug, type_id } = location;
  const isSkiable = skiAreaLocationTypes.map(({ id }) => id).includes(type_id);
  return `/location/${slug || shortname}/${isSkiable ? 'snow-summary' : 'weather'}`;
}

export function getPrecipUnits(units, useSymbol = false) {
  if (units === 'imperial') {
    return useSymbol ? '"' : 'in';
  }

  return 'mm';
}

export function getSnowUnits(units, useSymbol = false) {
  if (['imperial', 'us', 'uk'].includes(units)) {
    return useSymbol ? '"' : 'in';
  }

  return 'cm';
}

export function getTemperatureUnits(units) {
  const imperialUnits = ['imperial', 'us'];
  return imperialUnits.includes(units) ? '°F' : '°C';
}

export function getWindUnits(units) {
  const imperialUnits = ['imperial', 'us'];
  return imperialUnits.includes(units) ? 'mph' : 'kmh';
}

/**
 * @see https://github.com/lodash/lodash/issues/2184#issuecomment-620456264
 */
export function findMode(values) {
  const instanceCounts = values.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  const maximumCount = Math.max(...Object.values(instanceCounts));
  const mode = Object.keys(instanceCounts).filter((item) => instanceCounts[item] === maximumCount)[0];

  return Number(mode);
}

export function formatCloudCover(value, showSign = true) {
  if (typeof value === 'number') {
    // Convert decimal value to a whole number then round to the nearest 10
    const valueRounded = Math.round((value * 100) / 10) * 10;
    return showSign ? `${valueRounded}%` : `${valueRounded}`;
  }

  return dataNotAvailable;
}

export function formatElevation(elevation, units) {
  if (typeof elevation === 'number') {
    const unit = units === 'metric' ? 'm' : 'ft';
    return `${elevation.toLocaleString('en-US')} ${unit}`;
  }

  return dataNotAvailable;
}

export function formatElevationRounded(elevation, units) {
  if (typeof elevation === 'number') {
    const elevationRounded = Math.round(elevation / 100) * 100;
    return formatElevation(elevationRounded, units);
  }

  return dataNotAvailable;
}

export function formatForecastDate(dateTimeUtc, timezone) {
  if (typeof Intl !== 'undefined' && Intl.DateTimeFormat) {
    return dayjs.utc(dateTimeUtc).tz(timezone).format('ddd MMM DD h:mma z');
  }

  return dataNotAvailable;
}

export function formatNumber(value) {
  if (typeof value !== 'number') {
    return dataNotAvailable;
  }

  if (typeof Intl !== 'undefined' && Intl.NumberFormat) {
    return Intl.NumberFormat('en-us').format(value);
  }

  return value;
}

export function formatSlr(slr) {
  if (typeof slr !== 'number') {
    return dataNotAvailable;
  }

  if (Math.round(slr) === 0) {
    return '0';
  }

  return `${Math.round(slr)}:1`;
}

export function formatSnow(snow, units, useSymbol = false) {
  if (typeof snow === 'number') {
    return `${snow.toLocaleString('en-US')}${getSnowUnits(units, useSymbol)}`;
  }

  return dataNotAvailable;
}

export function formatPercent(value, useSymbol = true) {
  if (typeof value !== 'number') {
    return dataNotAvailable;
  }

  if (typeof Intl !== 'undefined' && Intl.NumberFormat) {
    const percent = Intl
      .NumberFormat('en-us', { style: 'percent' })
      .format(value);

    return useSymbol ? percent : percent.replace('%', '');
  }

  const symbol = useSymbol ? '%' : '';
  return `${(value * 100).toFixed(0)}${symbol}`;
}

/**
 * Use exponent notation to round values half way up.
 * @see https://en.wikipedia.org/wiki/Rounding#Rounding_half_up
 * @see http://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export function roundToPrecision(value, precision) {
  const value100 = `${value}e${precision}`;
  return Number(`${Math.round(value100)}e-${precision}`);
}

/**
 * Round precip half way up and display with two decimal places.
 */
export function formatPrecip(precip, units = 'imperial', useSymbol = false) {
  if (typeof precip !== 'number') {
    return dataNotAvailable;
  }

  const precipRounded = Number(roundToPrecision(precip, 2)).toFixed(2);

  if (useSymbol) {
    return `${precipRounded}${getPrecipUnits(units, useSymbol)}`;
  }

  return String(precipRounded);
}

export function formatPrecipChance(value) {
  if (typeof value === 'number') {
    // Convert decimal value to a whole number then round to the nearest 5
    const valueRounded = Math.round((value * 100) / 5) * 5;
    return formatPercent(valueRounded / 100);
  }

  return dataNotAvailable;
}

export function formatRelativeHumidity(value, useSymbol = true) {
  if (typeof value === 'number') {
    if (value < 1) {
      return formatPercent(value, useSymbol);
    }

    const valueRounded = Math.round(value);

    return useSymbol ? `${valueRounded}%` : String(valueRounded);
  }

  return dataNotAvailable;
}

export function formatTemp(temp, units, $config) {
  if (typeof temp === 'number') {
    return `${Math.round(temp)} ${getTemperatureUnits(units, $config)}`;
  }

  return dataNotAvailable;
}

export function formatWind(wind, units = 'imperial', showUnits = true, wind_dir_label = '') {
  if (typeof wind === 'number') {
    const windRounded = Math.round(wind / 5) * 5;

    if (windRounded === 0) {
      return 'Calm';
    }

    if (showUnits) {
      return `${wind_dir_label}${windRounded} ${getWindUnits(units)}`;
    }

    return `${wind_dir_label}${windRounded}`;
  }

  return dataNotAvailable;
}

function getAlertField(alert, field) {
  return alert?.[field] || alert?.level?.[field] || defaultColor;
}

export function getAlertColorBackground(alert) {
  return getAlertField(alert, 'color_background');
}

export function getAlertColorForeground(alert) {
  return getAlertField(alert, 'color_foreground');
}

export function getAlertColorPrimary(alert) {
  return getAlertField(alert, 'color_primary');
}

export function getAlertDescription(alert) {
  return getAlertField(alert, 'description');
}

export function getAreaPercentOpen(resortReport) {
  return formatPercent(resortReport?.percent_open);
}

export function getClosedText(resortReport) {
  if (resortReport?.status_id === resortReportStatus.temporarilyClosed) {
    return 'Temporarily Closed';
  }

  if (resortReport?.status_id === resortReportStatus.closed) {
    return 'Closed';
  }

  return '';
}

export function getLastItem(array, defaultLastItem) {
  return array?.length ? array[array.length - 1] : defaultLastItem;
}

/**
 * Note that Array.prototype.findLast() is not used here since it's not available in Node.js!
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findLast#browser_compatibility
 */
export function getLastItemWithPrecipSnow(days = [], defaultLastItem = {}) {
  const mostRecent = [...days]
    .reverse()
    .find((day) => day.precip_snow !== null);
  return mostRecent || defaultLastItem;
}

export function getLastPrecipSnow(snowDaily) {
  const { precip_snow } = getLastItem(snowDaily, {});
  return typeof precip_snow === 'number' ? Math.round(precip_snow) : undefined;
}

/**
 * Use mask-image property so the background color of a SVG image can be styled.
 * @see https://stackoverflow.com/questions/13367868/how-to-modify-the-fill-color-of-an-svg-image-when-being-served-as-background-ima
 */
export function getMaskImageStyle(icon_url) {
  const url = `url(${icon_url})`;

  return {
    '-webkit-mask-image': url,
    '-webkit-mask-position': 'center',
    '-webkit-mask-repeat': 'no-repeat',
    'maskImage': url,
    'maskPosition': 'center',
    'maskRepeat': 'no-repeat',
  };
}

export const openMountainApiOptions = { validateStatus: (status) => status < 500 };

export function checkForOpenMountainApiError(response) {
  const { error, messages } = response;

  if (error) {
    const e = new Error(error);
    e.messages = messages?.length > 0
      ? messages
      : ['Something went wrong. Please try again.'];
    throw e;
  }
}

export function getOpenMountainApiUrl($config, path = '', query) {
  // Remove undefined or null values from query so they don't get passed on the URL
  const filterQuery = (o) => Object.keys(o).reduce((acc, key) => {
    if (o[key] !== undefined && o[key] !== null) {
      acc[key] = o[key];
    }

    return acc;
  }, {});

  const { openMountainApiKey, openMountainApiUrl } = $config;
  const url = `${openMountainApiUrl}/${path.replace(/^\//, '')}?v=1&api_key=${openMountainApiKey}`;

  // TEMP: Map legacy units to the units used by OpenMountain-API to avoid 400 errors. Eventually
  // this won't be necessary when the new units are used consistently in OpenSnow.
  if (query && query.units) {
    const newQuery = { ...query };

    if (newQuery.units === 'canada') {
      newQuery.units = 'metric';
    }
    else if (newQuery.units === 'uk') {
      newQuery.units = 'imperial';
    }
    else if (newQuery.units === 'us') {
      newQuery.units = 'imperial';
    }

    return `${url}&${new URLSearchParams(filterQuery(newQuery)).toString()}`;
  }

  if (query) {
    const filteredQuery = filterQuery(query);

    return Object.keys(filteredQuery).length
      ? `${url}&${new URLSearchParams(filteredQuery).toString()}`
      : url;
  }

  return url;
}

/**
 * This function was extracted from TrailCalculation::getPopRiskColor()
 * @see https://github.com/cloudninewx/OpenSummit-Web/blob/develop/app/Library/TrailCalculation.php#L127
 */
export function getPopRiskColor(maxPop) {
  if (maxPop > 60) {
    return riskColors.high;
  }

  if (maxPop > 30) {
    return riskColors.medium;
  }

  return riskColors.low;
}

export function getSnowBackgroundColor(snow = 0, units = 'imperial') {
  if (snow === 0) {
    return null;
  }

  return snow >= powderLevel[units] ? 'background-color-saturated-orange' : 'background-color-light-blue';
}

export function safeRound(value) {
  return typeof value === 'number' ? Math.round(value) : undefined;
}

export function getSnowDepthBackgroundColor(snow = 0, units = 'imperial') {
  const roundedSnow = safeRound(snow);

  if ((roundedSnow > 100 && units === 'imperial') || (roundedSnow > 254 && units === 'metric')) {
    return 'background-color-saturated-orange';
  }

  if (roundedSnow > 0) {
    return 'background-color-light-blue';
  }

  return null;
}

export function getSnowTextColor(snow = 0, units = 'imperial') {
  if (snow === 0) {
    return 'text-darkest-color';
  }

  return snow >= powderLevel[units] ? 'text-saturated-orange-color' : 'text-light-blue-color';
}

export function getWindDirection(dir) {
  if (dir < 33.75) return 'N';
  if (dir >= 33.75 && dir < 78.75) return 'NE';
  if (dir >= 78.75 && dir < 123.75) return 'E';
  if (dir >= 123.75 && dir < 168.75) return 'SE';
  if (dir >= 168.75 && dir < 213.75) return 'S';
  if (dir >= 213.75 && dir < 258.75) return 'SW';
  if (dir >= 258.75 && dir < 303.75) return 'W';
  if (dir >= 303.75 && dir < 348.75) return 'NW';
  if (dir >= 348.75) return 'N';

  return 'N';
}

/**
 * Return true if the resort report is valid.
 * @see https://github.com/cloudninewx/OpenSnow-Android-Neue/blob/develop/model/src/main/java/com/cloudnineweather/android/model/domain/mountain/MountainModel.kt#L489
 */
export function hasValidResortReport(location) {
  const { notAvailable, unknown } = resortReportStatus;
  const { resort_report } = location;
  const invalidStatus = [notAvailable, unknown];

  return resort_report !== null
    && location.has_resort_report
    && (invalidStatus.includes(resort_report?.status_id) === false);
}

export function isClosed(resortReport) {
  if (resortReport?.status_id) {
    const { temporarilyClosed, closed } = resortReportStatus;
    return [temporarilyClosed, closed].includes(resortReport.status_id);
  }

  return undefined;
}

export function isNotClosed(resortReport) {
  if (resortReport?.status_id) {
    const { temporarilyClosed, closed } = resortReportStatus;
    return [temporarilyClosed, closed].includes(resortReport.status_id) === false;
  }

  return undefined;
}

export function isExperimentalSource(sourceId, dataSourcesById, fields) {
  const betaField = fields.find((field) => {
    const dataSource = dataSourcesById[sourceId];

    return dataSource
      ?.attribution
      ?.find((attribution) => attribution.is_experimental
      && attribution.applies_to.includes(field));
  });

  return !!betaField;
}

export function isEstimatedSource(sourceId, dataSourcesById) {
  return dataSourcesById[sourceId]?.is_estimate;
}

export function isEstimated(location, dataSourcesById) {
  const { source_id } = getLastItem(location.history_snow_daily, {});
  return isEstimatedSource(source_id, dataSourcesById);
}

export function isOld(date) {
  const now = dayjs().utc();
  const then = dayjs(date);
  return now.diff(then, 'days') >= 1;
}

export function isMetric(units) {
  return units === 'metric';
}

export function isSavedLocation(location) {
  return location?.id > 0;
}

export function isScreenSm() {
  const mediaQuery = window.matchMedia('(max-width: 767px)');
  return mediaQuery.matches;
}

export function isScreenLg() {
  const mediaQuery = window.matchMedia('(min-width: 992px)');
  return mediaQuery.matches;
}

export function parseOpenMountainApiError(error) {
  const {
    data,
    message,
    statusCode,
    statusMessage,
    stack,
  } = error || {};

  return {
    data,
    fatal: true,
    message: data?.messages?.length ? data.messages.join(' ') : message,
    statusCode,
    statusMessage,
    stack,
  };
}

export function showImage(imageUrl) {
  const items = [{
    src: imageUrl,
    type: 'image',
  }];

  const options = {
    autoFocus: false,
    infinite: false,
  };

  window.Fancybox.show(items, options);
}
